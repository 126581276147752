<template>
  <section>
    <div>
      <el-form :model="formItem" :rules="rules" ref="formItem">
        <el-form-item prop="tel">
          <el-input type="text" v-model="formItem.tel" :placeholder="$t('formRule.tel.required')" @keyup.enter.native="login"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="formItem.password" auto-complete="off" :placeholder="$t('formRule.password.required')" @keyup.enter.native="login"></el-input>
        </el-form-item>
        <el-form-item prop="captcha">
          <el-input type="text" v-model="formItem.captcha" auto-complete="off" :placeholder="$t('formRule.captcha.required')" @keyup.enter.native="login"></el-input>
          <span v-html="captcha" @click="getCaptcha"></span>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login">{{ $t('button.login') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import { mixin } from '@/mixins/index'

export default {
  mixins: [mixin()],
  data () {
    return {
      captcha: '',
      formItem: {
        tel: '',
        password: '',
        captcha: ''
      },
      rules: this.$validata.getRules([
        { value: 'tel', labal: 'tel' },
        { value: 'password', labal: 'password' }
      ])
    }
  },
  methods: {
    getCaptcha () {
      this.getFetch('/api/auth/captcha').then(response => {
        this.captcha = response.body
      })
    },
    login () {
      this.$refs['formItem'].validate((valid) => {
        if (valid) {
          this.postFetch('api/auth/index', this.formItem).then(response => {
            this.$store.dispatch('setUserInfo', response.userInfo)
            this.$store.dispatch('setMenu', response.menu)
            this.$router.push({ path: '/index' })
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  },
  created () {
    this.getCaptcha()
  }
}
</script>

<style lang="less">
</style>
